import React from "react";
import "./App.css";
import pic from "./treason.png";

function App() {
  return (
    <main>
      <h1>Penguins Only!</h1>

      <p>
        <img src={pic} height={400} style={{ margin: "0 auto" }} />
      </p>

      <p>We give to charity!</p>
    </main>
  );
}

export default App;
